@import "../../../scss/variables";
@import "../../../scss/functions";
@import "../../../scss/mixins/hacks";
@import "../../../scss/mixins/direction";

.top-bar {
  width: 100%;
  background: $topbar-bg;
  box-shadow: $topbar-shadow;
  font-size: 14px;
  color: $topbar-font-color;

  .top-bar-main {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .topbar_buttons {
      display: flex;
      gap: 10px;

      .language-button {
        display: flex;
        align-items: center;
        cursor: pointer;
        gap: 4px;
        position: relative;

        .menu-none {
          display: none;
        }

        svg {
          transition: .5s;

          &.rotT_fms {
            transform: rotate(180deg);
          }
        }

        .menu-block {
          position: absolute;
          top: 100%;
          //right: -15px;
          right: 0;
          z-index: 201;
          transition-delay: 0s, 0s, 0s;
          opacity: 1;
          visibility: visible;
          transform: rotateX(0deg);
          transition: transform .2s;

          .menu {
            color: #353535;
            background: #fff;
            list-style: none;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: .02em;
            font-weight: 500;
            padding: 6px 0;
            width: 150px;
            box-shadow: $menu-topbar-shadow;

            .menu_item {
              background: transparent;
              border: none;
              width: 100%;
              font-weight: inherit;
              display: flex;
              align-items: center;
              gap: 20px;
              padding: 8px;
              color: #121212;
              transition: .5s;

              &:hover {
                background: $menu-item-hover-bg;
              }

              .menu__icon {
                display: flex;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .top-bar {
    display: none;
  }

  .language-button {
    border-bottom: 1px solid #ebebeb;
    display: grid;
    grid-template-columns: 20px auto 55px;
    gap: 15px;
    align-items: center;
    font-size: 16px;
    color: #121212;
    padding: 18px 5px;

    .mobile_currency__symbol {
      border: 1px solid #f1f1f1;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 5px;
      flex-shrink: 0;
      grid-column: 1/2;
      grid-row: 1/2;
      max-width: 20px;
    }

    .mobile-links__item-title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }

    svg {
      margin: 0 auto;
      transition: .3s;
    }

    .rotT_fms {
      transform: rotate(180deg);
      transition: .3s;
    }

    .menu-block {
      display: block;
      grid-column: 1/4;
      animation: showHide .5s;
      padding-left: 24px;
      border-top: 1px solid #ebebeb;
      margin-bottom: -18px;

      .menu {
        .menu_item {
          width: 100%;
          display: flex;
          align-items: center;
          gap: 10px;
          cursor: pointer;
          height: 30px;
        }
      }
    }

    @keyframes showHide {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    .menu-none {
      display: none;
    }
  }

  //.language-button {
  //  display: flex;
  //  align-items: center;
  //  cursor: pointer;
  //  gap: 4px;
  //  position: relative;
  //
  //  .menu-none {
  //    display: none;
  //  }
  //
  //  svg {
  //    transition: .5s;
  //
  //    &.rotT_fms {
  //      transform: rotate(180deg);
  //    }
  //  }
  //
  //  .menu-block {
  //    position: absolute;
  //    top: 100%;
  //    right: -15px;
  //    z-index: 201;
  //    transition-delay: 0s, 0s, 0s;
  //    opacity: 1;
  //    visibility: visible;
  //    transform: rotateX(0deg);
  //    transition: transform .2s;
  //
  //    .menu {
  //      color: #353535;
  //      background: #fff;
  //      list-style: none;
  //      font-size: 14px;
  //      line-height: 16px;
  //      letter-spacing: .02em;
  //      font-weight: 500;
  //      padding: 6px 0;
  //      width: 150px;
  //      box-shadow: $menu-topbar-shadow;
  //
  //      .menu_item {
  //        background: transparent;
  //        border: none;
  //        width: 100%;
  //        font-weight: inherit;
  //        display: flex;
  //        align-items: center;
  //        gap: 20px;
  //        padding: 8px;
  //        color: #121212;
  //        transition: .5s;
  //
  //        &:hover {
  //          background: $menu-item-hover-bg;
  //        }
  //
  //        .menu__icon {
  //          display: flex;
  //        }
  //      }
  //    }
  //  }
  //}
}