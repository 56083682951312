@import "../../../scss/variables";

.home-product-container {
  margin: 47px auto !important;
  @media (max-width: 600px) {
    margin: 10px auto !important;
  }

}

.block-products__body {
  width: 100%;
  display: flex;

  .block-products__list {
    display: grid;
    grid-template-columns: repeat(4, calc((100% - 75px) / 4));
    width: 100%;
    gap: 25px;

    @media (max-width: 991px) {
      grid-template-columns: repeat(3, calc((100% - 50px) / 3));
    }

    @media (max-width: 600px) {
      grid-template-columns: repeat(2, calc((100% - 25px) / 2));
    }

    @media (max-width: 425px) {
      grid-template-columns: repeat(2, calc((100% - 10px) / 2));
      gap: 10px;
    }
  }

  .product-card-parent {
    margin-bottom: 20px;
  }
}

.product-card {
  position: relative;
  flex-direction: column;
  background: $product-card-bg;
  width: 100%;
  flex-shrink: 0;

  .product-card__image {
    &:hover {
      .product-image__body {
        .item_overlay {
          background-color: gray;
        }
      }
    }

    .product-image__body {
      display: block;
      position: relative;
      -webkit-box-shadow: 0 5px 10px 2px rgb(34 60 80 / 20%);
      box-shadow: 0 5px 10px 2px rgb(34 60 80 / 20%);
      //TODO!!! stexa pagac
      width: 100%;
      //width: 150px;
      aspect-ratio: 1/1;
      max-height: 359px;

      &:hover {
        .img_btn_wrapper {
          opacity: 1;
        }
      }

      .item_overlay {
        height: 100%;
        width: 100%;
        background-color: transparent;
        opacity: 0.4;
        display: block;
        z-index: 1;
        position: absolute;
        margin: 0;
        transition: background-color 0.2s;
      }

      .img_btn_wrapper {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        position: absolute;
        z-index: 3;
        height: 100%;
        width: inherit;
        opacity: 0;
        transition: opacity 1s;
        cursor: pointer;

        .product-card__addToCart {
          background: $main-theme-color-light;
          border: none;
          width: 227px;
          height: 41px;
          margin-bottom: 10px;
          font-size: 16px;
        }
      }


      .img_wrapper {
        width: 100%;
        aspect-ratio: 1;
        object-fit: contain;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        img {
          object-fit: contain;
          @media only screen and (max-width: 768px) {
            width: 100%;
            height: 100%;
          }
          @media only screen and ((max-width: 430px) and (min-width: 420px)) {
            width: 193px;
            height: 193px;
          }
        }
      }
    }
  }

  .product-card__info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 14px;

    .product-card__name {
      margin: 0;
      font-size: 15px;
      height: 20px;
      font-weight: bold;
      flex-grow: 1;
      overflow: hidden;

      a {
        color: $body-font-color;
        transition: 0.15s;
      }

      a:hover {
        color: $main-theme-color-light;
      }
    }

    .product-card-description {
      font-size: 15px;
      height: 25px;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .product-card__actions {
    .product-card__availability-mobile {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .product-card__availability {
        display: none;
      }

      .prices {
        font-weight: 500;
        color: #000;
        display: flex;
        gap: 10px;

        .new_price {
          color: $price-new-color;
        }

        .old_price {
          //margin-left: 10px;
          color: $price-old-color;
          font-weight: $font-weight-normal;
          font-size: 13px;
          text-decoration: line-through;
        }
      }

      .product-card__buttons {
        display: inline-block;
        float: right;
        cursor: pointer;

        .product-card__wishlist {
          .wishlist-circle {
            path {
              fill: #ccc;

              &:focus {
                outline: none;
              }
            }
          }

          .wishlist-circle:focus {
            outline: none !important
          }

          .wishlist-icon-wrapper {
            cursor: pointer;
            margin-left: 23px;
            //TODO!!! if we dont need this remove
            //padding-bottom: 10px;

            path {
              stroke: #AAA;
            }

            &:hover {
              path {
                stroke: $main-theme-color-light;
              }
            }
          }

          .wishlist-circle-clicked {
            path {
              fill: $main-theme-color-dark;
            }
          }

          .inner-wishlist {
            path {
              fill: $main-theme-color-light;

              > &:focus {
                outline: none;
              }
            }

            > &:focus {
              outline: none;
            }
          }
        }
      }
    }

    .product-card__addToCart {
      height: max-content;
      width: 100%;
      font-size: 14px;
      margin-top: 5px;
      background: $main-theme-color-light;
      border: none;

      &.hide-for-desktop {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .home-product-container {
    max-width: 900px;
  }

  .block-products__body {
    display: block;
  }

  .product-card {
    .product-card__image {
      .product-image__body {
        .item_overlay {
          display: none;
        }

        .img_btn_wrapper {
          z-index: unset;

          .product-card__addToCart {
            display: none;
          }
        }
      }
    }

    .product-card__actions {
      .product-card__availability-mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .product-card__availability {
          display: none;
        }
      }

      .product-card__addToCart {
        &.hide-for-desktop {
          display: block;
        }
      }
    }
  }

  .product-card {
    .product-card__image {
      .product-image__body {
        .item_overlay {
          display: none;
        }

        .img_btn_wrapper {
          z-index: unset;

          .product-card__addToCart {
            display: none;
          }
        }
      }
    }

    .product-card__actions {
      .product-card__availability-mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .product-card__availability {
          display: none;
        }
      }

      .product-card__addToCart {
        &.hide-for-desktop {
          display: block;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .product-card {
    .product-card__actions {
      .product-card__availability-mobile {
        .prices {
          font-size: 15px;

          .old_price {
            font-size: 13px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .product-card {
    .product-card__actions {
      .product-card__availability-mobile {
        .prices {
          font-size: 14px;

          .old_price {
            font-size: 12px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .product-card {
    .product-card__actions {
      .product-card__availability-mobile {
        .prices {
          font-size: 12px;

          .old_price {
            font-size: 10px;
          }
        }
      }
    }
  }
}