@import "../../scss/variables";
@import '../../scss/functions';
@import '../../scss/mixins/direction';

.faq_component {
  .faqs {
    display: flex;
    flex-direction: column;

    .faq_component_item {
      border-bottom: 1px solid #BBB;

      .faq_title_wrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        gap: 10px;
        cursor: pointer;

        .faq_title {
          font-size: 16px;
          font-weight: 600;
          width: calc(100% - 55px);
        }

        span{
          width: 15px;
          height: 0;
          border: 1px solid black;

          &.plus{
            position: absolute !important;
            height: unset !important;
            right: 20px;
            transform: rotate(90deg);
            transition: .5s;
          }

          &.open{
            transform: rotate(0deg);
          }
        }
      }

      .faq_body {
        max-height: 0;
        padding: 0 20px;
        overflow: hidden;
        transition: all .5s linear;

        p{
          margin: 0;
        }
      }
    }
  }
}

.icon_component {
  svg {
    width: inherit !important;
    height: inherit !important;
  }
}

.block-products {
  margin-bottom: 0;
}

.b-container {
  min-height: 0;
  margin-bottom: 0;
  margin-top: 60px;
  position: relative;

  h1, h2, h3, h4, h5, h6, span, p {
    word-break: break-word;
  }
}

.topped_margin_off {
  margin-top: unset;
}

.zg-grid-col {
  width: 100%;
  padding: 5px 10px;

  img {
    width: 100%;
  }
}

.zg-row {
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);

  > * {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
  }

  > .zg-dragforborder {
    box-sizing: border-box;
    background-color: $background-color_1;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
  }
}

.builder_component {
  position: relative;

  span:not(:global(.shared_title-line-fms__G_D1_)) {
    position: relative !important;
  }

  img {
    position: unset !important;
    max-width: unset !important;
    max-height: unset !important;
    min-width: unset !important;
    min-height: unset !important;
    width: 100% !important;
    height: 100% !important;
  }

  a {
    img {
      cursor: pointer !important;
    }
  }
}

.video_wrapper {
  position: relative;
  padding-bottom: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


.home-product-container {
  margin: 47px auto !important;
}

@media screen and (max-width: 991px) {
  .home-product-container {
    max-width: 900px;
  }

  .zg-col-lg {
    flex: 1 0 0;
  }
}

@media screen and (min-width: 767px) {
  .zg-col-md {
    flex: 1 0 0;
  }
}

@media screen and (max-width: 600px) {
  .zg-col-sm {
    flex: 1 0 0;
  }
}
@keyframes shakeFrame {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
}

.scale {
  transition: transform 0.3s ease-in-out;
  transform-origin: center; /* Ensures it scales from the center */
}

.bounce:hover {
  animation: bounce 0.3s ease-out;
}
.scale:hover {
  transform: scale(1.1);
}
.shake:hover {
  animation: shakeFrame 0.3s ease-in-out;
}

@keyframes bounce {
  0% {
      transform: translateY(0);
  }

  50% {
      transform: translateY(-10px);
  }

  100% {
      transform: translateY(0);
  }
}

.fade-up {
  transition: transform 0.5s ease-in-out, background 0.5s ease-in-out, box-shadow 0.5s ease-in-out !important;
}

.fade-up:hover {
  transform: translateY(-20px) !important;
  background: #6366f1 !important;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2) !important;
  color: white !important;
}
