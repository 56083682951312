@import "scss/variables";

.mobilemenu {
  display: none;
}

@media screen and (max-width: 991px) {
  .mobilemenu {
    display: block;
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 60px;
    width: 100%;
    height: calc(100% - 158px);
    z-index: 199;
    transition: visibility 0s .3s;
    overflow-y: scroll;

    &.mobilemenu--open {
      visibility: visible;
      opacity: 1;
      transition-delay: 0s;
    }

    .mobilemenu__backdrop {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: $mobilemenu-backdrop-bg;
      transition: opacity .3s;
      opacity: 0.9;
    }

    .mobilemenu__body {
      position: absolute;
      top: 0;
      width: 100%;
      background: $mobilemenu-bg;
      box-shadow: $mobilemenu-shadow;
      transition: transform .3s;
      display: flex;
      flex-direction: column;

      .mobilemenu__content {
        flex-grow: 1;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        margin: 0 10px 80px;

        .mobile-links {
          list-style: none;
          padding: 0;
          margin: 0;

          .mobile-links__item {
            &:last-child {
              border-bottom: none;
            }

            .mobile-links__item-title {
              display: flex;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;
              height: 100%;
              padding: 5px;
              border-bottom: 1px solid #ebebeb;
              min-height: 60px;

              .link-label {
                width: 100%;
                display: flex;
                text-align: left;
                align-items: center;
                gap: 15px;
              }

              .mobile-links__item-link {
                flex-grow: 1;
                color: $mobilemenu-font-color;
                border: none;
                margin: 0;
                background-color: transparent;
                line-height: 21px;
                font-size: 16px;
                padding: 7px 20px;
                display: flex;
                justify-content: left;
                align-items: center;
                text-align: left;

                &:focus {
                  outline: none;
                }
              }

              .mobile-links__item-toggle {
                border: none;
                background: transparent;
                padding: 0;
                width: 55px;
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: inherit;
                fill: $mobilemenu-font-muted-color;
                transition: fill 0.2s;

                .mobile-links__item-arrow {
                  transition: .3s;
                }
              }
            }

            .mobile-links__item-sub-links {
              visibility: hidden;
              overflow: hidden;
              height: 0;
              opacity: 0;
              transition: height .3s, opacity .3s, visibility 0s .3s;

              .mobile-links--level--1 {
                font-size: 14px;
                line-height: 14px;
              }

              //.mobile-links--level--2 {
              //  margin-left: 10px;
              //}
              //
              //.mobile-links--level--3 {
              //  margin-left: 20px;
              //}
              //
              //.mobile-links--level--4 {
              //  margin-left: 30px;
              //}
              //
              //.mobile-links--level--5 {
              //  margin-left: 40px;
              //}
              //
              //.mobile-links--level--6 {
              //  margin-left: 50px;
              //}
              //
              //.mobile-links--level--7 {
              //  margin-left: 60px;
              //}
              .mobile-links--level--2,
              .mobile-links--level--3,
              .mobile-links--level--4,
              .mobile-links--level--5,
              .mobile-links--level--6,
              .mobile-links--level--7 {
                margin-left: 20px;

              }

            }

            &.mobile-links__item--open {
              & > .mobile-links__item-sub-links {
                visibility: visible;
                height: 100%;
                opacity: 1;
                transition-delay: 0s, 0s, 0s;
              }

              & > .mobile-links__item-title {
                .mobile-links__item-arrow {
                  transition: .3s;
                  transform: rotate(180deg);
                }
              }
            }
          }
        }

        .mob-links__item {
          border-bottom: 1px solid #ebebeb;
          padding: 18px 5px;
          margin: 0;

          .mobile-links__blok {
            display: flex;
            gap: 15px;
            align-items: center;
            font-size: 16px;
            color: $light-opposite-color;
          }
        }
      }

      .mobileMenuFooter {
        padding: 0 10px;
        background: rgba(13, 71, 161, 0.05);

        .mobile-footer-dropdown {
          .empty_contacts_mobile {
            display: none !important;
          }

          .mobile-footer-dropdown-divs {
            min-height: 60px;
            border-bottom: 1px solid white;
            font-size: 15px;
            color: #121212;
            display: flex;
            align-items: center;

            .footer-contacts {
              display: flex;
              flex-direction: column;
              justify-content: center;
              width: 100%;

              .footer-title {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin: 10px 0;
                padding: 0 10px;
                font-size: 16px;
                gap: 10px;

                &.footer-title-opened {
                  margin-top: 18px;

                  .mobile-footer-arrow {
                    svg {
                      transform: rotate(180deg);
                      transition: .3s;
                    }
                  }
                }

                .mobile-footer-arrow {
                  margin-left: auto;
                  transition: transform 0.3s;

                  svg {
                    transition: .3s;

                    path{
                      fill: #999!important;
                    }
                  }
                }
              }

              .footer-contacts__contacts {
                flex-direction: column;
                gap: 10px;
                font-size: 15px;
                list-style: none;
                margin: 10px 0;
                padding: 0 40px;
                text-align: left;

                .contactsAddress {
                  white-space: pre-line;
                  position: relative;
                  padding: 0;
                  line-height: 19px;

                  p, a {
                    color: $body-font-color;
                    font-size: 16px;
                    width: 100%;
                    word-wrap: break-word;
                  }
                }
              }
            }
          }

          .empty_socials {
            display: none !important;
          }

          .mobile-footer-dropdown-last-divs {
            min-height: 60px;
            display: flex;
            align-items: center;

            .footer-newsletter__social-links {
              display: flex;
              justify-content: flex-start;
              padding: 0 10px;

              .social-links__list {
                list-style: none;
                display: flex;
                flex-wrap: wrap;
                gap: 14px;

                .social-links__item {
                  .social-links__link {
                    span {
                      position: relative !important;
                      width: 30px !important;
                      height: 30px !important;
                    }

                    img {
                      width: 100% !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px){
  .mobilemenu {
    height: calc(100% - 122px);
top: 60px;
  }
}

@media screen and (max-width: 600px) {
  .mobilemenu {
    .mobilemenu__body {
      .mobileMenuFooter {
        .mobile-footer-dropdown {
          width: 100%;
          border-bottom: 1px solid silver;
        }
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .mobilemenu {
    .mobilemenu__backdrop {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: $mobilemenu-backdrop-bg;
      opacity: .9;
      transition: opacity 0.3s;
    }

    .mobilemenu__body {
      position: absolute;
      top: 0;
      width: 100%;
      background: $mobilemenu-bg;
      box-shadow: $mobilemenu-shadow;
      transition: transform 0.3s;
      display: flex;
      flex-direction: column;

      .mobilemenu__content {
        flex-grow: 1;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        //margin: 40px 10px 80px;
      }

      .mobileMenuFooter-fms {
        padding: 0 10px;
        background: rgba(13, 71, 161, 0.05);

        .mobile-footer-dropdown {
          border-bottom: 1px solid silver;
          width: 100%;

          .mobile-footer-dropdown-divs {
            min-height: 60px;
            border-bottom: 1px solid white;
            font-size: 15px;
            color: #121212;
            display: flex;
            align-items: center;

            .footer-contacts {
              display: flex;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}