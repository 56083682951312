.dropList {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.dropLisItem {
    padding: 0;
    margin: 0;
}

.dropListItemTitle,
.dropListItemDecr {
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

.dropListItemTitle:hover {
    text-decoration: underline;
}

.selectedTitle {
    text-decoration: underline;
    color: #10428b;
}

.dropListItemDecr {
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 5px;

    p {
        margin: 0;
    }
}

.content {
    width: 100%;
    text-wrap: wrap;
    flex-shrink: 0;

    a {
        gap: 10px;
    }
}