.slider{
  width: 100% ;

  .slide_wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    span {
      width: 100% !important;
      height: 100% !important;

      img {
        width: 100% !important;
        height: 100%;
        position: absolute !important;
        object-fit: cover;
      }
    }

    .content_wrapper {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      padding: 60px;

      .content {
        display: flex;
        flex-direction: column;

        .content_title {
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 4px;
        }

        .content_description {
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 16px;
        }

        .content_button {
          padding: 7px 37px;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 400;
          border: 1px solid currentColor;
          color: currentColor;

          &:hover {
            background: rgba(0, 0, 0, .1);
          }
        }
      }
    }
  }

  :global(.slick-arrow){
    z-index: 1;
    width: unset;
    height: unset;
    opacity: .8;

    &:hover{
      rect{
        fill: #DEDEDE;
      }
    }
  }

  :global(.slick-prev) {
    left: 16px;
  }

  :global(.slick-next) {
    right: 16px;
  }

  :global(.slick-dots){
    bottom: 16px;
    display: flex !important;
    justify-content: center;
    gap: 8px;

    li {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #B5B5B5;
      margin: 0;

      button {
        display: none;
      }
    }

    :global(.slick-active){
      background: #121212;
    }
  }
}